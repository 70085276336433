.logo {
  width: auto;
  height: 22px;
}

@media (--lg) {
  .logo {
    height: 26px;
  }
}

.logotype {
  fill: var(--text-color);
}
