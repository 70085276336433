.root {
  padding: 3rem 0;
  margin-top: 26vh;
  position: relative;
  font-size: 1.125rem;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--text-color);
    opacity: 0.15;
  }
}
