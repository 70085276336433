.root {
  margin: 5rem 0 3rem;
  max-width: 840px;

  & a {
    color: var(--text-primary);
    transition: 100ms ease-out;

    &:hover {
      color: var(--primary);
    }
  }

  & > h1 {
  }

  & > .desc {
    margin-top: 2em;
  }
}

@media (--sm) {
  .root {
    margin: 13vh 0;
    & > .desc {
      font-size: 1.5rem;
    }
  }
}
