.root {
  position: relative;
}

.teamPhoto {
  position: absolute;
  right: 0;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  min-height: 400px;
  max-height: 700px;
}

.service {
  margin-bottom: 13vh;

  & ul {
    margin: 2rem 0;
    padding-left: 1rem;

    & li {
      margin-bottom: 0.5rem;
    }
  }
}

@media (--sm) {
  .service {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;

    & ul {
      font-size: 1.25rem;
    }
  }
}
