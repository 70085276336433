.root {
}

.nav {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.item {
  margin-left: 2px;
}

.link {
  transition: 100ms ease-out;
  text-decoration: none;
  color: var(--text-color);
  padding: 0 0.5em;
  display: inline-block;

  &:hover {
    color: var(--primary);
  }

  &.active {
    color: var(--primary);
  }
}

@media (--sm) {
  .root {
    /* display: block; */
  }
}

@media (--lg) {
  .link {
    padding: 0 1rem;
    font-size: 1.125rem;
  }
}
