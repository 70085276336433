@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

    &:before {
    border: 2px solid #eee;
    border-top-color: var(--primary);
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    animation: spin 750ms ease-in-out infinite;
    margin-right: 16px;
  }
}

.root {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
}

@media (--sm) {
  .root {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
  }

  .project {
    &:nth-child(2n) {
      padding-top: 4rem;
    }
  }
}

@media (--lg) {
  .root {
    grid-gap: 2rem 6rem;
  }
}
