.items {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.logo {
  filter: grayscale(1);
  opacity: 0.75;
}

@media (--sm) {
  .items {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (--lg) {
  .items {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
