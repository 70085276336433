.list {
  margin-top: 6rem;
  margin-bottom: 6rem;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.item {
  padding-left: 0;

  &:not(:last-child) {
    margin-right: 2rem;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  color: var(--primary);
  border-bottom: 1px solid var(--primary);

  & > svg {
    margin-left: 0.5rem;
    width: 1em;
    height: 1em;
  }
}
