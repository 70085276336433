.root {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1001;
  background-color: var(--body-bg);
}

.navHeader {
  display: none;
}

.navToggle {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  display: none;

  & > span {
    width: 18px;
    height: 2px;
    background-color: var(--text-color);
    display: inline-block;
    margin: 2px 0;
  }
}

@media (--sm) {
  .root {
    align-items: flex-end;
  }

  .navHeader {
    display: block;
  }

  .navToggle {
    display: none;
  }
}

@media (--lg) {
  .root {
    padding: 2rem 3rem;
  }
}
