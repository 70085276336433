@font-face {
  font-family: "Grphk";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/GraphikLCG-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Grphk";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/GraphikLCG-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Grphk";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/GraphikLCG-Semibold.woff2") format("woff2");
}

:root {
  --font-family: "Grphk", sans-serif;
  --font-size: 16px;
  --font-weight-heading: 600;
  --line-height: 1.5;
  --line-height-heading: 1.1;
  --text-color: #1a1a1a;
  --primary: #28b469;
  --body-bg: #ffffff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-color: #f5f5f5;
    --body-bg: #242424;
    --primary: #34d07c;
  }
}

@custom-selector :--heading h1, h2, h3, h4, h5, h6;

html {
  box-sizing: border-box;
  font-size: var(--font-size);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: var(--font-family);
  line-height: var(--line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--body-bg);
}

:--heading {
  font-weight: var(--font-weight-heading);
  margin-top: 1em;
  margin-bottom: 0.25em;
  line-height: var(--line-height-heading);
  letter-spacing: -0.015em;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.725rem;
}

h3 {
  font-size: 1.45rem;
}

h4 {
  font-size: 1.125rem;
}

@media (--sm) {
  h1 {
    font-size: 3.815rem;
  }

  h2 {
    font-size: 3.125;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.375rem;
  }
}

@media (--lg) {
  h1 {
    font-size: 5rem;
    /* letter-spacing: -0.015em; */
  }

  h2 {
    font-size: 3.725rem;
  }

  h3 {
    font-size: 2.125rem;
  }

  h4 {
    font-size: 1.725rem;
  }
}

img {
  border-style: none;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

a {
  color: var(--primary);
  text-decoration: none;
}

:global .active {
  color: var(--primary);
}

:global .container {
  width: 100%;
  max-width: 1280px;
  padding: 0 1.5rem;
  margin: 0 auto;
}

@media (--sm) {
  :global .container {
    padding: 0 4rem;
  }
}
