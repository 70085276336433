.root {
  padding: 13vh 0;
  background-color: var(--body-bg);
  display: block;
  margin-top: 13vh;
  color: var(--text-color);
  transition: 100ms ease-out;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--text-color);
    opacity: 0.15;
  }

  &:hover {
    color: #fff;
    background-color: var(--primary);

    & svg {
      stroke: #fff;
      margin-right: -20px;
    }
  }
}

.desc {
  margin-top: 0;
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 2rem;

  & > svg {
    margin-left: auto;
    stroke-width: 0.5px;
    height: 40px;
    width: 40px;
    transition: 100ms ease-out;
  }
}

@media (--sm) {
  .heading {
    & > svg {
      width: 60px;
      height: 60px;
    }
  }
}

@media (--lg) {
  .heading {
    & > svg {
      width: 80px;
      height: 80px;
    }
  }
}
