.root {
  & a {
    color: var(--text-color);
    text-decoration: none;
    display: block;
    transition: 100ms ease-out;
    overflow: hidden;
    position: relative;

    & > img {
      transition: 300ms ease-out;
    }

    & > svg {
      width: 80px;
      height: 80px;
      margin-top: -40px;
      margin-left: -80px;
      stroke-width: 0.5px;
      stroke: #fff;
      position: absolute;
      transition: 200ms ease-out;
      opacity: 0;
      z-index: 1;
      left: 50%;
      top: 50%;
    }
  }

  &:hover {
    & a {
      color: var(--primary);

      & > img {
        transform: scale(1.1);
        /* filter: blur(1px); */
      }

      & > svg {
        opacity: 1;
        margin-left: -40px;
      }
    }
  }
}

.imgBlock {
  border-radius: 6px;
}

.desc {
  margin-top: 0;
}

@media (--sm) {
  .desc {
    font-size: 1.275rem;
  }
}
